<template>
  <div class="authentication-bg min-vh-100">
    <div class="bg-overlay bg-black"></div>

    <div class="container">
      <div class="d-flex flex-column min-vh-100 px-3">
        <div class="row justify-content-center my-auto">
          <div class="col-md-8 col-lg-6 col-xl-4">
            <div class="text-center py-5">

              <!-- // Logo -->
              <div class="mb-2 mb-md-4">
                <div class="d-block auth-logo">
                  <img src="@/assets/images/spocha-logo.png" alt=""
                       height="136" class="auth-logo-dark" />
                  <img src="@/assets/images/spocha-logo.png" alt=""
                       height="136" class="auth-logo-light" />
                </div>
              </div>

              <div class="mb-4">
                <div class="mb-1">
                  <img src="@/assets/images/spocha-text-logo.png" alt=""
                       height="56" class="auth-logo-dark" />
                </div>
                <div class="text-white font-size-24">歓迎致します!</div>
              </div>

              <div class="alert alert-success text-center mb-4" role="alert">
                {{ $t('account.emailSendClick') }}
              </div>

              <!-- Find Password Form Start -->
              <form @submit.prevent="tryToReset">

                <div class="form-floating form-floating-custom mb-3">
                  <input type="text"
                         v-model="userId"
                         class="form-control"
                         id="find-input-user-id"
                         placeholder="Enter User ID"
                         :class="{ 'is-invalid': submitted && $v.userId.$error }" />
                  <label for="find-input-user-id">{{ $t('account.id') }}</label>
                  <div class="form-floating-icon">
                    <i class="mdi mdi-email-outline"></i>
                  </div>
                  <div v-if="submitted && $v.userId.$error" class="invalid-feedback">
                    <span v-if="!$v.userId.required">{{ $t('validation.emailIdIsRequired') }}</span>
                    <span v-if="!$v.userId.email">{{ $t('validation.enterValidEmail') }}</span>
                  </div>
                </div>

                <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                <div class="mt-3 mb-5">
                  <button class="btn btn-info w-100" type="submit">{{ $t('btn.send') }}</button>
                </div>

                <div class="mt-5 mb-5 text-center">
                  <p>
                    <span class="me-3 text-white-50">{{ $t('account.toReturn') }}</span>
                    <router-link to="/login"
                                 class="fw-medium text-decoration-underline"
                                 style="color: #A0F519">{{ $t('account.signIn') }}</router-link>
                  </p>
                </div>

              </form>
              <!-- Find Password Form End -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import { required, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      userId: null,
      submitted: false,
      isAuthError: false,
      authError: null
    }
  },
  validations: {
    userId: { required, email }
  },
  methods: {
    ...loadingMethods,
    tryToReset() {
      const vm = this;
      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        vm.showOverlay();

        this.$_app.findPwd(vm.userId).then(res => {
          vm.hideOverlay();
          this.$router.push({ path: '/verification' });
        }).catch(err => {
          vm.hideOverlay();
          let errString = err.toString();
          let errType = '';

          if (errString.indexOf('400') !== -1) {
            if (err.response.data.message === 'EmailNotFound') {
              errType = i18n.t('alert.emailNotFound');
            } else {
              errType = i18n.t('alert.contactSystem');
            }
          } else {
            errType = i18n.t('alert.contactSystem');
          }

          vm.isAuthError = true;
          vm.authError = errType;
        });
      }
    }
  }
}
</script>
